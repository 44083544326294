<script>
    import { isLoading, loadingMessage } from '../stores.js';
    import { fade } from 'svelte/transition';
	import logo from '$lib/images/Latest Icons/Heatificate.png';

    let loadingClass;
    $: loadingClass = $isLoading ? "show" : "";
</script>

<style>
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7); /* Dimmed background */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
    }
    .loading-overlay.show {
        opacity: 1;
        pointer-events: all;
    }
    .loading-box {
        position: relative;
        background: white;
        width: 300px;
        padding: 20px;
        text-align: center;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        overflow: visible; /* Allows logo to overlap */
    }
    .logo {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 50px;
    }
    .loading-text {
        margin-top: 60px; /* Push text below logo */
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }
    .dots {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    .dot {
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background: #666;
        border-radius: 50%;
        animation: bounce 1.5s infinite;
    }
    .dot:nth-child(2) {
        animation-delay: 0.2s;
    }
    .dot:nth-child(3) {
        animation-delay: 0.4s;
    }
    @keyframes bounce {
        0%, 80%, 100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.5);
        }
    }
</style>

<div class={`loading-overlay ${loadingClass}`} transition:fade>
    <div class="loading-box">
        <img src={logo} alt="Heat-ificate" class="logo">

        <div class="logo"></div> <!-- Logo -->
        <div class="loading-text">
            {$loadingMessage}
        </div>
        <div class="dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</div>
